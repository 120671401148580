import React from 'react';
import './Form.css'
import './CheckoutApp.css'

interface CheckoutAppProps {
    url: string;
    iframeRef: React.MutableRefObject<HTMLIFrameElement>;
}

export const CheckoutApp = ({url, iframeRef}: CheckoutAppProps) => {
    return (
        <div className="checkoutAppWrapper">
            <iframe ref={iframeRef} className="appIframe" src={url} title="Checkout App" />
        </div>
    )
};
