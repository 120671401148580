import React from 'react';
import {TesterApp} from "./Components/TesterApp";

function App() {
  return (
    <TesterApp />
  );
}

export default App;
