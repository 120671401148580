import React from 'react';

export const Logo = ({className}: any) => (
    <svg className={className} data-bbox="14.93 6.27 1118.02 274.66" viewBox="14.93 6.27 1118.02 274.66" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation">
        <g>
            <path d="M1093.5 168.8s39.45-23.8 39.45-70.37c0-50.44-38.22-86.01-86.9-86.01H872.18v260.79h110.99v-38.4c0-16.63 7.22-26.55 19.31-26.55 14.37 0 19.48 13.38 19.48 25.9v39.05h110.99v-13.17c-.01-41.53-8.89-76.28-39.45-91.24zm-84.46-44.04h-25.88l.01-38.8h25.22c16.63 0 26.55 7.22 26.55 19.31 0 14.37-13.38 19.49-25.9 19.49z" fill="#56e39f" data-color="1"></path>
            <path d="M429.92 6.27c-69.01 0-130.67 44.46-130.67 124.95v24.77c0 80.5 61.66 124.94 130.67 124.94 69 0 130.67-44.44 130.67-124.94v-24.77c0-80.5-61.67-124.95-130.67-124.95zm19.4 156.56c0 16.63-7.22 26.55-19.31 26.55-14.37 0-19.48-13.38-19.48-25.9V123.7c0-16.63 7.22-26.55 19.32-26.55 14.36 0 19.48 13.38 19.48 25.9v39.78z" fill="#56e39f" data-color="1"></path>
            <path d="M164.94 170.07c0 16.63-7.22 26.55-19.31 26.55-14.37 0-19.48-13.38-19.48-25.9V131.2H14.93v19.18c0 76.95 52.2 130.54 130.61 130.54 70.73 0 130.61-41.18 130.61-119.82V12.44H164.94v157.63z" fill="#56e39f" data-color="1"></path>
            <path d="M846.76 12.44H735.78v38.4c0 16.63-7.22 26.55-19.31 26.55-14.37 0-19.48-13.38-19.48-25.9V12.44h-111V273.2h110.99v-38.39c0-16.63 7.22-26.55 19.31-26.55 14.37 0 19.48 13.38 19.48 25.9v39.05h110.99v-13.17c0-75.79-24.73-105.1-60.4-117.22 0 0 60.4-24.68 60.4-117.21h-.03l.03-.01V12.44z" fill="#56e39f" data-color="1"></path>
        </g>
    </svg>
);
