import React, {ChangeEvent, useRef, useState} from 'react';
import './TesterApp.css'
import {Grid, TextField} from "@mui/material";
import {Logo} from "./Logo";
import {Form} from "./Form";
import {CheckoutApp} from "./CheckoutApp";

export const TesterApp = () => {
    const [url, setUrl] = useState<string>('http://localhost:3000');
    const iframeRef = useRef<any>();

    const handleChangeLocation = (event: ChangeEvent<HTMLInputElement>) => {
        setUrl(event.target.value);
    };

    const handleSubmit = (data: string) => {
        if (!iframeRef || !iframeRef.current) {
            return;
        }

        iframeRef.current.contentWindow.postMessage(data, url);
    };

    return (
        <main className="outerWrapper">
            <div className="header">
                <Logo className="logo" />

                <div className="location">
                    <TextField
                        value={url}
                        onChange={handleChangeLocation}
                        label="Location"
                        fullWidth
                    />
                </div>
            </div>

            <div className="innerContainer">
                <Grid
                    container
                    justifyContent="space-between"
                    flexDirection="row"
                    className="container"
                >
                    <Grid item alignItems="stretch">
                        <CheckoutApp url={url} iframeRef={iframeRef} />
                    </Grid>
                    <Grid item flexGrow={2}>
                        <Form onSubmit={handleSubmit} />
                    </Grid>
                </Grid>
            </div>
        </main>
    );
};
