import React, {ChangeEvent, useState} from 'react';
import './Form.css'
import {TextField} from "@mui/material";
import Button from '@mui/material/Button';
import * as defaultMock from '../config/default_mock.json'
import {Grid} from "@mui/material";

interface IFormProps {
    onSubmit(data: string): void;
}

export const Form = ({onSubmit}: IFormProps) => {
    const [code, setCode] = useState<string>(JSON.stringify(defaultMock, null, "    "));

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setCode(event.target.value);
    };

    const handleSubmit = () => {
        onSubmit(code);
    };

    return (
        <div className="codeWrapper">
            <Grid
                container
                justifyContent="space-between"
                flexDirection="column"
                spacing={2}
                style={{height: '100%'}}
            >
                <Grid item flexGrow={3}>
                    <TextField
                        style={{height: '100%'}}
                        value={code}
                        onChange={handleChange}
                        label="Form"
                        multiline
                        rows={25}
                        fullWidth
                        InputProps={{style: {height: '100%'}}}
                    />
                </Grid>
                <Grid item className="buttonContainer">
                    <Button
                        onClick={handleSubmit}
                        disabled={!code.length}
                        variant="contained"
                        fullWidth
                    >
                        Load
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
};
